<!-- 商品统计 -->

<template>
  <div class="productStatistics">
    <div class="globle_border" v-loading="loading">
      <!-- echart -->
      <el-date-picker v-model="chartTime" type="month" value-format="yyyyMM" placeholder="选择月份"
        @change="changeChartTime"></el-date-picker>
      <div v-show="shopSale.length > 0" class="echarts">
        <div class="echart" id="main" style="width: 1600px; height: 400px;"></div>
      </div>
      <el-empty v-show="shopSale.length == 0" description="暂无图表数据"></el-empty>

      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="typeValue" placeholder="选择统计类型年月日" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 年 -->
        <el-date-picker v-if="typeValue == 3 || typeValue == ''" v-model="yearValue" type="year" key="year"
          :placeholder="datePickerPlaceholder" :readonly="!typeValue"
          @change="(value) => handleSelectData(value, 'year')" value-format="yyyy" clearable></el-date-picker>
        <!-- 月 -->
        <el-date-picker v-if="typeValue == 2" v-model="monthValue" type="monthrange" range-separator="至"
          start-placeholder="开始月份" end-placeholder="结束月份" value-format="yyyy-MM"
          @change="(value) => handleSelectData(value, 'month')">
        </el-date-picker>
        <!-- 日 -->
        <el-date-picker v-if="typeValue == 1" v-model="dataValue" type="daterange" key="daterange"
          popper-class="sift-data" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="(value) => handleSelectData(value, 'data')" clearable value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="statType" label="统计类型">
            <template slot-scope="scope">
              <span>{{ scope.row.statType == 1 ? '日期' : scope.row.statType == 2 ? '月度' : '年度' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="statPeriod" label="时间">
            <template slot-scope="scope">
              <span>{{ formatStatPeriod(scope.row.statPeriod) }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="sellAmount" label="销售额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="returnsAmount" label="退货额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="incomeAmount" label="结算金额(元)"></el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    // 初始化 chartTime 为当前月份
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = ('0' + (now.getMonth() + 1)).slice(-2); // getMonth 返回的月份是从0开始的，所以需要+1，并格式化为两位数
    return {
      loading: false,
      options: [{
        value: '3',
        label: '年度'
      }, {
        value: '2',
        label: '月度'
      }, {
        value: '1',
        label: '日期'
      },],

      typeValue: '', // 选择年月日类型
      yearValue: '', // 选择年
      monthValue: '', // 选择月
      dataValue: '', // 日
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      productValue: '', // 搜索商品名称
      barCode: '',
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取） 

      // echarts
      shopSale: [],
      // chartTime: `202404`,
      chartTime: `${currentYear}${currentMonth}`,
      myChart: null,
    }
  },
  created() {
    this.getSettlementProductStatList();
  },
  mounted() {
    this.initEcharts()
    this.setEchartsOption()
  },
  computed: {
    datePickerPlaceholder() {
      if (this.typeValue == '') {
        return "请先选择统计类型再选择时间";
      } else if (this.typeValue == 3) {
        return "选择年";
      } else {
        // 其他情况下的默认placeholder
        return "选择日期";
      }
    }
  },
  methods: {
    // 获取列表
    getSettlementProductStatList() {
      this.loading = true;
      let params = {
        statType: this.typeValue || null, // 统计类型
        productName: this.productValue || null, // 搜索商品名称
        barCode: this.barCode || null,
        startTime: this.startTime || null, // 开始时间
        endTime: this.endTime || null, // 结束时间
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.getSettlementProductStatList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatStatPeriod(statPeriod) {
      statPeriod = statPeriod?.toString();
      if (statPeriod?.length === 6) {
        // 如果长度是6，则假设是年份和月份，格式为YYYYMM
        const year = statPeriod.substring(0, 4);
        const month = statPeriod.substring(4, 6);
        return `${year}-${month}`;
      } else if (statPeriod?.length === 8) {
        // 如果长度是8，则假设包含年份、月份和日期，格式为YYYYMMDD
        const year = statPeriod.substring(0, 4);
        const month = statPeriod.substring(4, 6);
        const day = statPeriod.substring(6, 8);
        return `${year}-${month}-${day}`;
      } else {
        // 如果长度不是6也不是8，返回原始值
        return statPeriod;
      }
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getSettlementProductStatList();
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      if (!this.typeValue) {
        this.yearValue = '';
        this.monthValue = '';
        this.dataValue = '';
        this.startTime = '';
        this.endTime = '';
      }
      this.getSettlementProductStatList();
    },
    // 时间搜索
    handleSelectData(value, time) {
      this.clearData();

      if (time == 'year') {
        // 年
        this.yearValue = value;
        this.startTime = value?.replace(/-/g, "");
        this.endTime = value?.replace(/-/g, "");
      } else if (time == 'month') {
        // 月
        this.monthValue = value;
        if (this.monthValue && this.monthValue.length === 2) {
          this.startTime = value[0].replace(/-/g, "");
          this.endTime = value[1].replace(/-/g, "");
        } else {
          this.startTime = null;
          this.endTime = null;
        }
      } else if (time == 'data') {
        // 日
        this.dataValue = value;
        if (this.dataValue && this.dataValue.length === 2) {
          this.startTime = value[0].replace(/-/g, "");
          this.endTime = value[1].replace(/-/g, "");
        } else {
          this.startTime = null;
          this.endTime = null;
        }
      }
      this.getSettlementProductStatList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getSettlementProductStatList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getSettlementProductStatList();
    },
    changeChartTime() {
      if (!this.myChart) this.initEcharts()
      this.setEchartsOption()
    },
    // 初始化echarts
    initEcharts() {
      var chartDom = document.getElementById('main');
      this.myChart = echarts.init(chartDom);
    },
    setEchartsOption() {
      let params = {
        statPeriod: this.chartTime, // 统计时期（如2024、202401、20240108）
        statType: 2, // 统计类型：0总；1日；2月；3年
      };
      this.$axios.get(this.$api.settlementProductSale, { params }).then((res) => {
        if (res.data.code == 100) {
          this.shopSale = res.data.result
          let result = res.data.result.map(e => {
            return {
              ...e,
              value: e.incomeAmount,
              name: e.productName,
            }
          })
          var option = {
            title: {
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                var html = ''
                if (params.data.productName) html += `<div style="font-size:16px;font-weight: bold;">${params.data.productName}</div>`
                if (params.data.incomeAmount) html += `<div>结算金额：${params.data.incomeAmount}元</div>`
                if (params.data.proportion) html += `<div>占比：${params.data.proportion}%</div>`
                return html;
              }
              // 简单用法
              // formatter: ` 
              //   <strong style="font-size:16px;">{b}</strong><br>
              //   结算金额 : {c}元<br>
              //   收入占比 : {d}%`,
              //默认值null，内容格式器
              //折线（区域）图、柱状（条形）图、K线图 : a（系列名称），b（类目值），c（数值）, d（无）
              //散点图（气泡）图 : a（系列名称），b（数据名称），c（数值数组）, d（百分比）
              //地图 : a（系列名称），b（区域名称），c（合并数值）, d（无）
              //饼图、雷达图、仪表盘、漏斗图: a（系列名称），b（数据项名称），c（数值）, d（饼图：百分比 | 雷达图：指标名称）
            },
            legend: {
              orient: 'vertical',
              left: 'right'
            },
            color: ['#f76848', '#74bdcf', '#7db7f7', '#e7bcf3', '#ffa500', '#4dd0e1', '#ff7f50', '#c0c0c0', '#800080', '#ffd700', '#228b22', '#ff4500'],
            series: [
              {
                type: 'pie',
                radius: '80%',
                avoidLabelOverlap: true, // 防止标签重叠
                data: result,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }

          option && this.myChart.setOption(option);
        }
      })
    },
  }

}



</script>

<style scoped lang="scss">
.productStatistics {
  padding-bottom: 30px;

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
